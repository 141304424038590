import { Module } from 'vuex'
import { RootState } from '@/store'
import { axiosAdminBack, axiosCore } from '@/plugins/axios'
import { PaymentTargetInfo } from '@/interfaces/payments-targets'

export interface PaymentTargetsState {
    streamersPaymentTargetsInfo: PaymentTargetInfo[]
    paymentsLoading: boolean
    paymentsError: boolean
    totalPaymentsSize: number
}

export const paymentTargetsStore: Module<PaymentTargetsState, RootState> = {
    state: {
        streamersPaymentTargetsInfo: [],
        paymentsLoading: false,
        paymentsError: false,
        totalPaymentsSize: 0,
    },
    getters: {
        streamersPaymentTargetsInfo: state => state.streamersPaymentTargetsInfo,
        totalPaymentsSize: state => state.totalPaymentsSize,
        isPaymentsLoading: state => state.paymentsLoading,
        isPaymentsError: state => state.paymentsError,
    },
    mutations: {
        setStreamersPaymentTargetsInfo(state, streamersPaymentMethods: PaymentTargetInfo[]) {
            state.streamersPaymentTargetsInfo = streamersPaymentMethods
        },
        updateStreamerPaymentTargetInfo(state, { userId, recipient} ) {
            state.streamersPaymentTargetsInfo.forEach((item: PaymentTargetInfo) => {
                if (item._id === userId) {
                    item.recipient = recipient
                    item.billingMethod = null
                }
            })
        },
        streamersPaymentLoading(state, value: boolean) {
            state.paymentsLoading = value;
        },
        streamersPaymentError(state, value: boolean) {
            state.paymentsError = value;
        },
        setTotalPaymentsSize(state, value: number) {
            state.totalPaymentsSize = value;
        }
    },
    actions: {
        async getStreamersPaymentTargets({ commit }, payload) {
            const {
                paginationToken,
                limit,
                userId,
                name,
                recipient,
                billingMethod,
            } = payload;

            commit('streamersPaymentLoading', true);
            commit('streamersPaymentError', false);

            await axiosAdminBack.get<any, { paymentTargets: PaymentTargetInfo[], totalCount: number }>('/v3/agency/goddessPaymentTargets', {
                params: {
                    ...(paginationToken && { paginationToken }),
                    ...(limit && { limit }),
                    ...(userId && { userId }),
                    ...(name && { name }),
                    ...(recipient && { recipient }),
                    ...(billingMethod && { billintMethod: billingMethod }),
                }
            })
                .then(data => {
                    commit('setStreamersPaymentTargetsInfo', data.paymentTargets);
                    commit('setTotalPaymentsSize', data.totalCount);

                    commit('streamersPaymentLoading', false);
                })
                .catch(() => {
                    commit('streamersPaymentError', true);
                })
        },
        async getStreamerBillingMethods(_, userId) {
            return await axiosCore.get('/billingMethods/list', { params: { userId } })
        },
        async updateStreamerPayTarget({ commit }, data) {
            const params = { recipient: data.recipient } as Record<string, string>

            if (data.billingMethodId) {
                params.billingMethodId = data.billingMethodId
            }

            return await axiosCore.post(`/billingMethods/payTarget?userId=${data.userId}`, params ).then((response) => {
                if (response.status) {
                    commit('updateStreamerPaymentTargetInfo', data)
                }
            })
        }
    },
}
