
import {
    computed,
    defineAsyncComponent,
    defineComponent,
    watch,
    onMounted,
    ref
} from 'vue'

import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { init as fbInit } from '@/customPlugins/fb-pixel'
import { init as gtagInit } from '@/customPlugins/gtag'
import { init as tiktokInit } from '@/customPlugins/tiktok'
import config from '@/config'

import { useSubheader } from '@/composables/subheader'
import { useBreakPoints } from '@/composables/breakpoints'
import { useStreamer } from '@/composables/streamer'
import { useAnalytics } from '@/composables/analytics'
import { useAppLoading } from '@/composables/appLoading'

import visibilityHelper from '@/helpers/visibility'
import { storageJsonParser } from '@/helpers/general'
import authHelper from '@/helpers/auth'

import ToastsPool from '@/components/ToastsPool.vue'
import NotificationsPool from '@/components/NotificationsPool.vue'
import * as Sentry from "@sentry/vue";

export default defineComponent({
    components: {
        ToastsPool,
        NotificationsPool,
        DesktopLayout: defineAsyncComponent(
            () => import('@/layouts/desktop.vue')
        ),
        MobileLayout: defineAsyncComponent(
            () => import('@/layouts/mobile.vue')
        ),
        AuthLayout: defineAsyncComponent(() => import('@/layouts/auth.vue')),
        SignupLayout: defineAsyncComponent(
            () => import('@/layouts/signup.vue')
        ),
        SignupAltLayout: defineAsyncComponent(
            () => import('@/layouts/signupAlt.vue')
        ),
        EmptyLayout: defineAsyncComponent(() => import('@/layouts/empty.vue')),
        ...visibilityHelper.globalVisibleComponents
    },
    setup() {
        const store = useStore()
        const route = useRoute()
        const { initBreakpoints } = useBreakPoints()
        const { initRouteTab } = useSubheader()
        const { activityPeriods } = useStreamer()
        const { initAnalytics } = useAnalytics()
        const { setAppLoading } = useAppLoading()

        const defaultLayoutName: string = 'default'

        const layoutComponentName = computed(
            () => `${route.meta.layout || defaultLayoutName}-layout`
        )

        const globalVisibleComponentsNames = Object.keys(
            visibilityHelper.globalVisibleComponents
        )

        const visiblePool = computed(() => store.getters.visiblePool)
        const isAuth = computed<boolean>(() => store.getters.isAuth)
        const isDataFetched = ref<boolean>(false)
        const query = computed<any>(() => route.query)

        const routeName = computed(() => route.name)

        watch(routeName, (val, prevValue) => {
            if (val !== prevValue) {
                initRouteTab()
                store.commit('setPageFilters', {})
                store.commit('setPageFiltersModel', {})
                store.commit('setPageSortings', {})
                store.commit('setDateRange', ['', ''])

                if (activityPeriods.value.length > 0) {
                    store.commit('setSelectedPeriods', [
                        activityPeriods.value[0]
                    ])
                }
            }

            window.scrollTo(0, 0)
        })

        watch(
            query,
            async (routeQuery) => {
                await queryLoginFromAdminPanel(routeQuery)
            },
            { immediate: true }
        )

        if (config.facebookId) {
            fbInit(config.facebookId)
        }

        if (config.gaId) {
            gtagInit(config.gaId)
        }

        if (config.tiktokIds) {
            tiktokInit(config.tiktokIds)
        }

        function getWeekDatesFrom(dateString: string) {
            const inputDate = new Date(dateString);

            const dayOfWeek = inputDate.getDay();

            const daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

            const mondayDate = new Date(inputDate);
            mondayDate.setDate(inputDate.getDate() - daysToMonday);

            const sundayDate = new Date(mondayDate);
            sundayDate.setDate(mondayDate.getDate() + 6);

            const formatDate = (date: any) => date.toISOString().split('T')[0];

            return [formatDate(mondayDate), formatDate(sundayDate)]
        }

        onMounted(async () => {
            setVhSize()
            initBreakpoints()

            await checkOnboardingStatus()
            window.addEventListener('resize', setVhSize)

            const date1 = new Date();

            const formattedDate1 = `${date1.getFullYear()}-${(date1.getMonth() + 1).toString().padStart(2, '0')}-${date1.getDate().toString().padStart(2, '0')}`;

            const date = getWeekDatesFrom(formattedDate1)

            if (isAuth.value) {
                setAppLoading(true)
                await store.dispatch('getProfile')

                await Promise.allSettled([
                    store.dispatch('loadInvitationLink'),
                    store.dispatch('loadSupportContacts'),
                    store.dispatch('getStreamersWarningStatistics'),
                    store.dispatch('getAgencyPaymentHistory'),
                    store.dispatch('getStreamersLevelsInfo'),
                    store.dispatch('getKpiConfigInfo')
                ]).catch(console.error)

                if (route.name !== 'dashboard') {
                    store.dispatch('loadStreamersActivities', {
                        currentDates: [date],
                    })
                }

                isDataFetched.value = true
                setAppLoading(false)

                const user = localStorage.getItem('user');
                const parsedUser = JSON.parse(user);

                Sentry.setTag("user_email", parsedUser.profile.email);
                Sentry.setTag("user_id", parsedUser.profile._id);
            }
        })

        async function queryLoginFromAdminPanel(routeQuery: any) {
            if (routeQuery.authToken && routeQuery.id) {
                if (!isAuth.value) {
                    await loginFromAdminPanel(
                        routeQuery.authToken,
                        routeQuery.id
                    )
                    await store.dispatch('getProfile')
                } else {
                    await loginFromAdminPanel(
                        routeQuery.authToken,
                        routeQuery.id
                    )
                }

                location.href = location.pathname
            }
        }

        async function loginFromAdminPanel(
            authToken: string,
            agencyId: string
        ) {
            authHelper.logout({ withReload: false })
            await store.dispatch('authFromAdminPanel', { authToken, agencyId })
        }

        async function checkOnboardingStatus() {
            const isOnboardingScreensEmpty =
                isAuth.value &&
                !storageJsonParser(
                    `onboardingScreens_${store.getters?.profile?._id}`
                )
            if (isOnboardingScreensEmpty && !localStorage.support) {
                await store.dispatch('loadOnboardingScreens')
                location.reload()
            }
        }

        function setVhSize() {
            const vh = window.innerHeight / 100
            document.documentElement.style.setProperty('--vh', `${vh}px`)
        }

        function initAnalyticsScript() {
            const landingKey = config.analyticsKey
            if (landingKey) {
                initAnalytics(landingKey)
            }
        }

        initAnalyticsScript()

        return {
            layoutComponentName,
            globalVisibleComponentsNames,
            isDataFetched,
            visiblePool
        }
    }
})
