import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5be5237d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modals-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToastsPool = _resolveComponent("ToastsPool")!
  const _component_NotificationsPool = _resolveComponent("NotificationsPool")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layoutComponentName), { isDataFetched: _ctx.isDataFetched }, null, 8, ["isDataFetched"])),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.globalVisibleComponentsNames, (componentName) => {
      return (_openBlock(), _createBlock(_Transition, {
        key: componentName,
        name: "animationAppear"
      }, {
        default: _withCtx(() => [
          (componentName in _ctx.visiblePool)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(componentName)))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1024))
    }), 128)),
    _createVNode(_component_ToastsPool),
    _createVNode(_component_NotificationsPool)
  ], 64))
}