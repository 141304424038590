import { Module } from 'vuex'
import { RootState } from '@/store'
import { axiosCore, axiosAnalytics, axiosAdminBack } from '@/plugins/axios'
import { PenaltiesData } from '@/interfaces/penalty'
import { InviteItem, InvitationLink, StreamersActivities, StreamerActivity, DayActivity } from '@/interfaces/streamer'
import { LogItem } from '@/interfaces/log'
import { EarningsByDate, EarningsDataWithDate } from '@/interfaces/earnings'
import { StreamersLevelData, UserLevelInfo, KPIConfigInfo } from '@/interfaces/levels'
import { formatDate } from '@/helpers/date'
import { sortByDateField } from '@/helpers/data'
import { calculateStreamerStatus } from '@/helpers/status'

export interface StreamersState {
    invites: InviteItem[]
    invitesMap: { [key: string]: InviteItem }
    invitationLink: InvitationLink | null
    streamersActivities: StreamersActivities
    penaltiesData: PenaltiesData | null
    levelInfo: UserLevelInfo | null,
    kpiConfigInfo: KPIConfigInfo | null,
    balance: number,
    warningStatistic: LogItem[]
    currentStreamerEarnings: EarningsDataWithDate[],
    // can be used to inform about "loading" request state
    // for example use to show loader until data received
    loadingQueriesStatus: { [key: string]: boolean }
    streamersLevelsInfo: StreamersLevelData[],
    profiles: any[],
    inviteds: any[],
    allProfiles: any[],
    activeProfiles: any[],
    totalAllProfiles: number,
    totalActiveProfiles: number,
    totalProfiles: number,
    invitedTotal: number,
    isLoadingActivities: boolean,
    isProfilesLoading: boolean,
    invitedMetrics: any,
    isInvitedLoading: boolean,
    activityLoadingError: boolean,
    errorPage: string,
}

function checkIsStreamerActive(dayActivity: DayActivity): boolean {
    const activeStreamerActivityFields: Array<keyof DayActivity> = [
        'coinsTotal',
        'totalPenalty',
        'bonus',
        'bonusPenalty',
        'thisPeriod',
        'lastPeriodTotal',
        'redeemablePoints',
        'streamersIncome',
        'agencyCommission',
        'agencyTotalRevenue',
        'first_launch',
        'matching',
        'warning',
        'penalty',
        'penaltyDown',
    ]

    return activeStreamerActivityFields.some(fieldName => dayActivity[fieldName])
}

function activeStreamerActivityFields(dayActivity: DayActivity): DayActivity {
    if (checkIsStreamerActive(dayActivity)) {
        dayActivity.activeStreamers = 1
    }

    return dayActivity
}

export const streamersStore: Module<StreamersState, RootState> = {
    state: {
        invites: [],
        invitesMap: {},
        invitationLink: null,
        streamersActivities: {},
        penaltiesData: null,
        levelInfo: null,
        kpiConfigInfo: null,
        balance: 0,
        warningStatistic: [],
        currentStreamerEarnings: [],
        loadingQueriesStatus: {},
        streamersLevelsInfo: [],
        profiles: [],
        inviteds: [],
        allProfiles: [],
        activeProfiles: [],
        totalAllProfiles: 0,
        totalActiveProfiles: 0,
        totalProfiles: 0,
        invitedTotal: 0,
        isLoadingActivities: false,
        isProfilesLoading: false,
        invitedMetrics: {},
        isInvitedLoading: false,
        activityLoadingError: false,
        errorPage: '',
    },
    getters: {
        invites: state => state.invites.map((invite: InviteItem) => {
            invite.statusCalculated = calculateStreamerStatus(invite, state.streamersActivities[invite.userId]?.days)
            return invite
        }),
        inviteds: state => state.inviteds.map((invite: InviteItem) => {
            invite.statusCalculated = calculateStreamerStatus(invite, state.streamersActivities[invite.userId]?.days)
            return invite
        }),
        profiles: state => state.profiles.map((invite: InviteItem) => {
            invite.statusCalculated = calculateStreamerStatus(invite, state.streamersActivities[invite.userId]?.days)
            return invite
        }),
        allProfiles: state => state.allProfiles.map((invite: InviteItem) => {
            invite.statusCalculated = calculateStreamerStatus(invite, state.streamersActivities[invite.userId]?.days)
            return invite
        }),
        errorPage: state => state.errorPage,
        isInvitedLoading: state => state.isInvitedLoading,
        invitedMetrics: state => state.invitedMetrics,
        invitedTotal: state => state.invitedTotal,
        isLoadingActivities: state => state.isLoadingActivities,
        isProfilesLoading: state => state.isProfilesLoading,
        activeProfiles: state => state.activeProfiles,
        activityLoadingError: state => state.activityLoadingError,
        totalAllProfiles: state => state.totalAllProfiles,
        totalActiveProfiles: state => state.totalActiveProfiles,
        totalActivities: state => state.streamersActivities.length,
        totalProfiles: state => state.totalProfiles,
        invitesMap: state => state.invitesMap,
        invitationLink: state => state.invitationLink,
        streamersActivities: state => state.streamersActivities,
        penaltiesData: state => state.penaltiesData,
        penaltyLevel: state => {
            if (state.penaltiesData?.history.length === 0) {
                return 0
            }

            return (state.penaltiesData?.curentPenaltyLevel || 0)
        },
        levelInfo: state => state.levelInfo,
        balance: state => state.balance,
        warningStatistic: state => state.warningStatistic,
        currentStreamerEarnings: state => state.currentStreamerEarnings,
        isLoadingStreamersActivities: state => 'loadStreamersActivities' in state.loadingQueriesStatus,
        streamersLevelsInfo: state => state.streamersLevelsInfo,
        kpiConfigInfo: state => state.kpiConfigInfo,
    },
    mutations: {
        setPenaltiesData(state, data) {
            state.penaltiesData = data
        },
        setLevelInfo(state, data: UserLevelInfo) {
            state.levelInfo = data
        },
        setKpiConfigInfo(state, data: KPIConfigInfo) {
            state.kpiConfigInfo = data
        },
        updateStreamerLevelInfoInInvites(state, {userId, data}: {userId: string, data: UserLevelInfo}) {
            const streamerToUpdate = state.invites.find(el => el.userId === userId)
            if (streamerToUpdate) {
                streamerToUpdate.level =  data.level
                streamerToUpdate.levelExchangeRate = data.exchangeRate
            }

            if (state.invitesMap.hasOwnProperty(userId)) {
                state.invitesMap[userId].level = data.level
                state.invitesMap[userId].levelExchangeRate = data.exchangeRate
            }
        },
        setBalance(state, balance) {
            state.balance = balance
        },
        setInvitedStreamers(state, invites: InviteItem[]) {
            state.invites = invites
            state.invitesMap = invites.reduce((acc: { [k: string]: InviteItem }, item) => {
                acc[item.userId] = item

                return acc
            }, {})
        },
        setInvitedMetrics(state, metrics) {
            state.invitedMetrics = metrics;
        },
        setProfiles(state, profiles) {
            state.profiles = profiles.users
            state.totalProfiles = profiles.count
        },
        setAllProfiles(state, profiles) {
            state.allProfiles = profiles.users
            state.totalAllProfiles = profiles.count
        },
        setActiveProfiles(state, profiles) {
            state.activeProfiles = profiles.users
            state.totalActiveProfiles = profiles.count
        },
        setInvited(state, invitations) {
            state.inviteds = invitations.users;
            state.invitedTotal = invitations.count;
        },
        setLoadingActivities(state, loading) {
            state.isLoadingActivities = loading;
        },
        setProfilesLoading(state, loading) {
            state.isProfilesLoading = loading;
        },
        setInvitedLoading(state, loading) {
            state.isInvitedLoading = loading;
        },
        setInvitationLink(state, link: InvitationLink) {
            state.invitationLink = link
        },
        setStreamersActivities(state, activities: StreamersActivities) {
            state.streamersActivities = activities
        },
        setWarningStatistic(state, statistic: LogItem[]) {
            state.warningStatistic = statistic
        },
        setError(state, page) {
            state.activityLoadingError = true;
            state.errorPage = page;

            setTimeout(() => {
                state.activityLoadingError = false;
            }, 10000)
        },
        setCurrentStreamerEarnings(state, earnings: EarningsDataWithDate[]) {
            sortByDateField(earnings, 'date', 'asc')
            state.currentStreamerEarnings = earnings
        },
        setLoadingQueryStatus(state, queryName: string) {
            state.loadingQueriesStatus[queryName] = true
        },
        setStreamersLevelsInfo(state, levelsData: StreamersLevelData[]) {
            state.streamersLevelsInfo = levelsData
        },
        removeLoadingQueryStatus(state, queryName: string) {
            delete state.loadingQueriesStatus[queryName]
        },
    },
    actions: {
        async loadStreamersActivities({ commit }, payload) {
            commit('setLoadingQueryStatus', 'loadStreamersActivities')

            const { currentDates } = payload;

            function addOneDay(dateString: string) {
                const date = new Date(dateString);
                date.setDate(date.getDate() + 1);
                const month = date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
                const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
                return `${date.getFullYear()}-${month}-${day}`;
            }

            const updatedDateArray = currentDates.map(([startDate, endDate]: any) => [
                startDate,
                addOneDay(endDate),
            ]);

            commit('setLoadingActivities', true);

            await axiosAnalytics.post<any, StreamerActivity[]>('/v3/agency/goddessActivity', {
                periods: updatedDateArray
            })
                .then((activities: StreamerActivity[]) => {
                    const streamersActivities: StreamersActivities = activities.reduce((acc: StreamersActivities, streamerActivity) => {
                        streamerActivity.days = streamerActivity.days.map(activeStreamerActivityFields)

                        acc[streamerActivity.goddessId] = streamerActivity

                        return acc
                    }, {})
                    commit('setStreamersActivities', streamersActivities)
                    commit('setLoadingActivities', false);
                })
                .catch(() => {
                    commit('setLoadingActivities', false);
                    commit('setError', 'streamers')
                })
                .finally(() => {
                    commit('removeLoadingQueryStatus', 'loadStreamersActivities')
                })
        },
        async loadInvitation({ commit }, payload) {
            commit('setInvitedLoading', true);
            await axiosAdminBack.post<any, InviteItem[]>('/v2/agency/goddesses/invited', payload)
                .then(data => {
                    commit('setInvited', data)
                    commit('setInvitedLoading', false);
                })
                .catch(() => {
                    commit('setInvitedLoading', false);
                    commit('setError', 'invites')
                })
        },
        async loadProfiles({ commit }, payload) {
            commit('setProfilesLoading', true);
            await axiosAdminBack.post<any, InviteItem[]>('/v2/agency/goddesses/profiles', payload)
              .then(data => {
                  commit('setProfiles', data)
                  commit('setProfilesLoading', false);
              })
              .catch(() => {
                  commit('setProfilesLoading', false);
                  commit('setError', 'profiles')
              })
        },
        async loadInvites({ commit }) {
            await axiosCore.get<any, InviteItem[]>('/agency/goddesses/profiles')
                .then(data => commit('setInvitedStreamers', data))
        },
        async loadInvitationLink({ commit }) {
            await axiosCore.get<any, InvitationLink>('/agency/getInviteLink')
                .then(data => {
                    commit('setInvitationLink', data)
                })
        },
        async getInvitedMetrics({ commit }, ) {
            await axiosAdminBack.get<any, InviteItem[]>('v2/agency/goddesses/invitedMetrics')
                .then(data => commit('setInvitedMetrics', data))
        },
        async getStreamerProfile(_, userId) {
            return await axiosCore.get(`/agency/goddess/profile`, { params: { userId } })
        },
        async getStreamerFullEarnings({ commit }, userId) {
            return await axiosCore.get<any, EarningsByDate>(`/agency/goddess/fullEarnings`, { params: { userId } })
                .then(data => {
                    const streamerFullEarnings: EarningsDataWithDate[] = Object.entries(data).map(([date, earnings]) => {
                        const [year, month, day] = date.split('_').map(item => Number(item))

                        return {
                            ...earnings,
                            date: formatDate({ date: new Date(year, month, day) }),
                        }
                    })

                    commit('setCurrentStreamerEarnings', streamerFullEarnings)
                })
        },
        async getStreamerPenaltyHistory({ commit }, userId) {
            await axiosCore.get('/agency/goddess/penaltyHistory', { params: { userId } })
                .then(data => {
                    commit('setPenaltiesData', data)
                })
                .catch(console.warn)
        },
        async getStreamerMatchingBanHistory(_, params) {
            return await axiosCore.post(`/agency/goddess/matchingBanHistory`, { ...params })
        },
        async getStreamersWarningStatistics({ commit }) {
            await axiosAnalytics.get<any, { goddesses: LogItem[] }>('/agency/goddessWarningStatistic')
                .then(data => {
                    commit('setWarningStatistic', data.goddesses)
                })
        },
        async getStreamersLevelsInfo({ commit }) {
            await axiosAdminBack.get<any, { levels: StreamersLevelData[] }>('/v2/agency/goddessLevels')
                .then(data => {
                    commit('setStreamersLevelsInfo', data.levels)
                })
        },
        async getStreamerLevelInfo({ commit }, userId) {
            await axiosCore.get(`/agency/goddess/userLevelInfo?userId=${userId}`)
                .then(data => {
                    commit('setLevelInfo', data)
                    commit('updateStreamerLevelInfoInInvites', {userId, data} )
                })
        },
        async getStreamerBalance({ commit }, userId) {
            await axiosCore.get<any, { balance: number[] }>(`/balance?userId=${userId}`)
                .then(data => {
                    commit('setBalance', data.balance)
                })
        },
        async getKpiConfigInfo({ commit }) {
            await axiosCore.get<any, { kpiLevelsMap: any, minLevelPayOutThreshold: number }>('/goddess/kpiConfigInfo')
                .then(data => {
                    commit('setKpiConfigInfo', data)
                    console.log('setKpiConfigInfo', data)
                })
        }
    },
}
