import * as Sentry from "@sentry/vue";

export default function initSentry(app: any, router: any) {
    Sentry.init({
        app,
        dsn: "https://3dabfb9a1d332e5137220f220e80c4e8@o4505702935953408.ingest.us.sentry.io/4508850230067200",
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
            Sentry.httpClientIntegration(),
            Sentry.globalHandlersIntegration({
                onerror: true,
                onunhandledrejection: true
            })
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}
